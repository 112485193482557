import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../layouts";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";
import SEO from "../components/seo";
import ContactForm from "../modules/contact-form";
import Header from "../modules/header";
import PageBuilder from "../modules/page-builder";

export const query = graphql`
  query ContactPageQuery($id: String!) {
    page: sanityPageContact(id: { eq: $id }) {
      title
      slug {
        current
      }
      seo {
        title
        keywords
        image {
          asset {
            url
          }
        }
        description
      }
      breadcrumbs {
        title
        slug
      }
      _rawContactInfo(resolveReferences: { maxDepth: 5 })
      ...contactBuilderFields
      _rawBody(resolveReferences: { maxDepth: 5 })
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      heroTitle
      heroText
    }
  }
`;

const EventsIndex = ({ data }) => {
  const page = data.page;

  return (
    <Layout className="contact">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
      />
      <Header
        title={page.heroTitle || page.title}
        text={page.heroText}
        breadcrumbs={page.breadcrumbs}
        image={page.image}
        sidebar={page._rawContactInfo}
      />
      {page?._rawBody?.[0] && (
        <div className="m-body-content-wrapper container">
          <BlockContent
            className="m-body-content"
            blocks={page._rawBody}
            serializers={{
              serializers,
              types: {
                block: serializers.types.block,
                undefined: serializers.undefined,
                blockVideo: serializers.blockVideo,
                blockFigure: serializers.blockFigure,
                pdfButton: serializers.pdfButton,
              },
              marks: {
                link: serializers.marks.link,
              },
            }}
            renderContainerOnSingleChild={true}
          />
        </div>
      )}

      <ContactForm {...page.contact} />
      <PageBuilder builder={page.pageBuilder} />
    </Layout>
  );
};

EventsIndex.propTypes = {
  data: PropTypes.object,
};

export default EventsIndex;
