import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import log from "../helpers/log";
import PropTypes from "prop-types";
import LoaderRing from "../elements/loader/ring";

const ContactForm = ({
  title = "Get in Touch",
  intro = "Want to speak to GrainGrowers? Please use the form below.",
  error = "We were unable to process your request. Please try again later.",
  success = "Thanks for getting in touch!",
}) => {
  // Form data
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(null);

  const recaptchaRef = React.createRef();

  const submit = payload => {
    return new Promise((resolve, reject) => {
      const url = `https://submit-form.com/gLJDkxJ5`;
      const method = "POST";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(payload);

      setSubmitting(true);

      fetch(url, {
        method,
        headers,
        body,
      })
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const onFormSubmit = async e => {
    e.preventDefault();

    const data = {
      name,
      email,
      message,
    };
    try {
      // Get ReCaptcha response
      const token = await recaptchaRef.current.executeAsync();

      // Submit data w/ ReCaptcha token
      await submit({
        ...data,
        ...{
          "g-recaptcha-response": token,
        },
      });
      setSubmitSuccess(true);
    } catch (err) {
      log.error(err);
      setSubmitSuccess(false);
    }
  };

  return (
    <div className="m-contact-form-container container">
      <div className="m-contact-form">
        <h2>{title}</h2>
        <p>{intro}</p>
        <form action="" onSubmit={onFormSubmit}>
          <div className="field">
            <label className="label" htmlFor="name">
              Your Name
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={e => setName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="email">
              Your Email
            </label>
            <div className="control">
              <input
                className="input"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">
              Your Message
            </label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Include your message to GrainGrowers here"
                rows="3"
                name="message"
                value={message}
                onChange={e => {
                  e.preventDefault();
                  setMessage(e.target.value);
                }}
                required
              ></textarea>
            </div>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            badge="inline"
            sitekey="6LfbgAIcAAAAAOs3NLRZQPY37Y7QnF0Y8LUCqb9z"
          />
          {submitSuccess === null && (
            <div className="field">
              <div className="control">
                <button className="e-button -primary" disabled={submitting}>
                  {submitting ? <LoaderRing fill="#000" /> : "Send"}
                </button>
              </div>
            </div>
          )}
          {submitSuccess && (
            <div className="m-contact-form-success">{success}</div>
          )}

          {submitSuccess === false && (
            <div className="m-contact-form-error">{error}</div>
          )}
        </form>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
};

export default ContactForm;
