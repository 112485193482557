import React from "react";
import PropTypes from "prop-types";

const LoaderRing = ({ fill = "#fff" }) => (
  <div className="e-lds-ring" style={{ "--fill": fill }}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
LoaderRing.propTypes = {
  fill: PropTypes.string,
};
export default LoaderRing;
